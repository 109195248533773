
import './App.css';
import Header from './Component/Header';
import Footer from './Component/Footer';
import Home from './Component/Home';

function App() {
  return (
   <>
   <Header/>
   <Home/>
   <Footer/>
   
   </>
  );
}

export default App;
