import React from 'react'

const Footer = () => {
  return (
    <div className='container-fluid pt-md-5 pt-sm-2'>
       <div className='row '>
  <div className='col-12 text-center ' style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
    <p className='bg-grey pt-2 text-white'>Copyright @ Valley Of Flowers : India</p>
  </div>
</div>

    </div>
  )
}

export default Footer