import React, { useState } from 'react'

import { AiFillDashboard } from "react-icons/ai";
import { AiFillAlipayCircle } from "react-icons/ai";
import { ImAirplane } from "react-icons/im";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
        slidesToSlide: 3 // optional, default to 1.
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
    }
};





const Home = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState({
        username: "",
        email: "",
        mobile: ""
    })


    const resetData = () => {
        setData({
            username: "",
            email: "",
            mobile: ""
        })
    }

    const handleValue = (e, name) => {
        const newValue = e.target.value;
        console.log(newValue, "lf")
        setData(prevData => ({
            ...prevData,
            [name]: newValue
        }));
    };

    const handlesubmit = async (e) => {
        // console.log("fxhsiuf")
        e.preventDefault();
        setIsLoading(true)
        try {
            const response = await fetch("https://acre.onrender.com/valley/vivek", {
                method: "POST", // Adjust the method as per your API
                headers: {
                    "Content-Type": "application/json" // Adjust headers as per your API
                },
                body: JSON.stringify(data) // Send form data in the request body
            });
            // console.log("hello",response)
            if (response.ok) {
                // Handle successful response
                const responseData = await response.json();
                resetData();
                setIsLoading(false)
                alert("We received your query , Our team contact too you soon !")

                console.log(responseData);
            } else {
                // Handle error response
                console.error("Error:", response.statusText);
            }
        } catch (error) {
            // Handle fetch error
            console.error("Fetch error:", error);
        }
    };

    // const [isContentVisible1, setIsContentVisible1] = useState(false);

    // const toggleContentVisibility1 = () => {
    //     setIsContentVisible1(!isContentVisible2);
    // };
    const [isContentVisible2, setIsContentVisible2] = useState(false);

    const toggleContentVisibility2 = () => {
        setIsContentVisible2(!isContentVisible2);
    };
    const [isContentVisible3, setIsContentVisible3] = useState(false);

    const toggleContentVisibility3 = () => {
        setIsContentVisible3(!isContentVisible3);
    };
    const [isContentVisible4, setIsContentVisible4] = useState(false);

    const toggleContentVisibility4 = () => {
        setIsContentVisible4(!isContentVisible4);
    };
    const [isContentVisible5, setIsContentVisible5] = useState(false);

    const toggleContentVisibility5 = () => {
        setIsContentVisible5(!isContentVisible5);
    };
    const [isContentVisible6, setIsContentVisible6] = useState(false);

    const toggleContentVisibility6 = () => {
        setIsContentVisible6(!isContentVisible6);
    };

    const [isContentVisible7, setIsContentVisible7] = useState(false);
    const toggleDescription7 = () => {
        setIsContentVisible7(!isContentVisible7);
    };
    const [isContentVisible8, setIsContentVisible8] = useState(true);
    const toggleDescription8 = () => {
        setIsContentVisible8(!isContentVisible7);
    };



    return (
        <>
            <div className='Container-fluid '>
                <div
                    id="carouselExampleIndicators"
                    className="carousel slide mb-2"
                    data-ride="carousel"
                >
                    <ol className="carousel-indicators">
                        <li
                            data-target="#carouselExampleIndicators"
                            data-slide-to={0}
                            className="active"
                        />
                        <li data-target="#carouselExampleIndicators" data-slide-to={1} />
                        <li data-target="#carouselExampleIndicators" data-slide-to={2} />
                    </ol>
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <img className="d-block w-100 slider" src="../image/Valley of flowers 1.webp" alt="First slide" />
                            <div className="carousel-caption d-none d-md-block">
                                <h5>Valley of Flowers Trek</h5>
                                <p>If you want to experience nature at its best</p>

                            </div>
                            <div className="carousel-caption  d-md-block">
                                <button type="button"

                                    data-toggle="modal"
                                    data-target="#exampleModal" className='btn  btn-sm slider-button ml-lg-5'>Enquiry</button>
                            </div>
                        </div>
                        <div className="carousel-item">

                            <img className="d-block w-100 slider" src="../image/Valley of flowers 2.webp" alt="Third slide" />
                            <div className="carousel-caption d-none d-md-block">
                                <h5>Valley of Flowers Trek</h5>
                                <p>If you want to experience nature at its best</p>

                            </div>
                            <div className="carousel-caption  d-md-block">
                                <button type="button"

                                    data-toggle="modal"
                                    data-target="#exampleModal" className='btn  btn-sm slider-button ml-lg-5'>Enquiry</button>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <img className="d-block w-100 slider" src="../image/Valley of flowers 3.webp" alt="Third slide" />
                            <div className="carousel-caption d-none d-md-block">
                                <h5 >Valley of Flowers Trek</h5>
                                <p>If you want to experience nature at its best</p>

                            </div>
                            <div className="carousel-caption  d-md-block">
                                <button type="button"

                                    data-toggle="modal"
                                    data-target="#exampleModal" className='btn  btn-sm slider-button ml-lg-5'>Enquiry</button>
                                {/* Button trigger modal */}

                            </div>
                        </div>
                    </div>

                </div>
                <div className='container pt-md-5 pt-sm-2 mb-3'>
                    <div className='row'>
                        <div className='col-lg-4 mb-2  col-md-4 col-sm-12 text-center'>
                            <div className='d-flex justify-content-center'>
                                <button className='btn btn-lg btn-dark active rounded-circle p-3 d-flex justify-content-centre'><AiFillDashboard /></button><br />
                            </div>
                            <strong >Duration</strong>
                            <br />
                            <p>6 day</p>

                        </div>
                        <div className='col-lg-4 mb-2 col-md-4 col-sm-12 text-center'>

                            <div className='d-flex justify-content-center'>
                                <button className='btn btn-lg btn-dark active rounded-circle p-3 d-flex justify-content-centre'><AiFillAlipayCircle /></button>
                            </div>
                            <strong>Starting Price</strong>
                            <br />
                            <p>10000 +GST</p>
                        </div>
                        <div className='col-lg-4 mb-2 col-md-4 col-sm-12 text-center'>
                            <div className='d-flex justify-content-center'>
                                <button className='btn btn-lg btn-dark active rounded-circle p-3 d-flex justify-content-centre'> <ImAirplane /></button>
                            </div>

                            <strong >Pickup Point</strong>
                            <br />
                            <p>Delhi</p>

                        </div>
                    </div>


                </div>
                <div className='container  pt-md-5 pt-sm-2 mb-3'>
                    <div className='row'>
                        <div className='col-lg-8 mb-3 col-md-8  col-sm-12'>
                            <div>
                                <p className=''> <span className=' font-weight-bold '>Valley Of Flowers Trek Itinerary<br />


                                </span>
                                    <p className='mb-3'>
                                        <span >
                                            <span className='font-weight-normal  text-wrap'  >
                                                Ready to explore the most lovely and relaxing Valley of Flowers Trek. If you are tired of
                                                following the same routine and want to get away from the hustle and bustle of city life, Our
                                                Trekking company has organized a wonderful trip to the Valley for you. The many areas of
                                                vibrant colors beneath the watchful gaze of the majestic snow-capped Himalayas. The trip
                                                will undoubtedly be one of the most memorable experiences of your life. This Trek highlights
                                                the wonders that Mother Nature has perfected. It is one of the world's most popular trekking
                                                destinations, nestled in interior of the Garhwal .
                                                {isContentVisible7 && (<span>
                                                    <br /><br />
                                                    If you want to experience nature at its best while getting away from the hustle and bustle of
                                                    everyday life, this journey is for you. The Valley of Flowers, located at 14,100 feet above sea
                                                    level, is a UNESCO World Heritage Site. During the winter months, this fascinating Valley
                                                    remains frozen; but, as the summers begin, it begins to bloom into a youthful prettiness as
                                                    snow melts. The trek regarded as an integral component of the Nanda Devi Biosphere
                                                    Reserve, which welcomes visitors from all over the world during the peak months, i.e., June
                                                    to September, in addition to a vast array of beautiful flowers in all colors and shapes. During
                                                    the walk, you will be able to see stunning natural scenery as well as uncommon creatures
                                                    such as blue sheep, red fox, and others. The magnificent rushing streams, stunning floral
                                                    fields, and breathtaking mountain views are among the highlights of this journey.<br /><br />
                                                    Prepare to discover the breathtaking scenery of lush surroundings, followed by the gleaming
                                                    snowy mountains of the Himalayas. When it comes to the most fascinating and enthralling
                                                    Valleys in India, this journey ranks first due to its wealth of colorful flowers and verdant
                                                    grassy areas. Thousands of blue Primula bloom in this Valley, creating a beautiful spectacle.
                                                    Primula is one of the most stunning Himalayan flowers. The greatest time to visit the
                                                    beautiful Valley in full bloom is between mid-July and mid-August. It would be helpful if
                                                    trekked from Govind Ghat to the Valley of Flowers, passing through Hemkund Sahib along
                                                    the way. This valley is around 5 kilometers from Ghangaria.<br /><br />
                                                    This Trek provides a natural tour through the essence of flora and fauna. In addition, this
                                                    Valley is home to over 300 rare and endangered floral species. Primulas, Calendula Daisies,
                                                    Orchids, and Poppies are among the rarer species. As you hike up toward the Valley, you'll
                                                    see some incredible Himalayan wildlife including blue sheep, black bears, and snow
                                                    leopards. The stunning Flora and Fauna make this location ideal for trekking. If you are an
                                                    adventurer, you will fall in love with the Valley's breathtaking views.
                                                    Make sure to have your camera ready since the Valley's breathtaking scenery awaits. You
                                                    should be aware that the trek is not simple due to the moderate difficulty level and somewhat
                                                    longer hiking days. This journey will test your endurance as there is a tough rise to Hemkund
                                                    Sahib. So, get yourself ready for the trek.<br /><br />
                                                    When you arrive at the Valley's entrance, you will be greeted with a breathtaking carpet of
                                                    multicolored flowers that will leave you awestruck. Any Himalayan enthusiast considering a
                                                    monsoon trek would undoubtedly prioritize this journey. This Valley is undeniably one of the
                                                    most lively and appealing regions in the West Himalayas. It is well-known for its diverse
                                                    flower and alpine tree populations, which add to the Valley's enchanting atmosphere. The
                                                    atmosphere is peaceful, and you will come across breathtaking natural sights and waterfalls
                                                    while trekking. If you enjoy mountain vistas and amazing natural sights, you should go on
                                                    this walk at least once in your life.<br /><br />
                                                    The Valley of Flowers Trek is one of India's most popular Himalayan treks. People who have
                                                    never traveled to the Himalayas have heard of the Valley of Flowers trip. But there's a
                                                    reason Valley of Flowers is so well-known: it's one of India's oldest recognized treks.
                                                    The Valley of Flowers National Park was established by the Indian government in 1980, and
                                                    it became a UNESCO World Heritage Site in 2002. This moved Valley of Flowers up the
                                                    bucket list of hikers from around the world. However, keep in mind that the Valley Of Flowers
                                                    Trek is not easy to navigate. It's a moderate fitness level trek with longer trekking days and a
                                                    difficult ascent to Hemkund Sahib to test your stamina. Make yourself comfortable for this
                                                    specific journey. We've talked extensively about this trip.
                                                </span>)}
                                                <span onClick={toggleDescription7}>
                                                    {isContentVisible7 ? <b>Read less...</b> : <b>Read More...</b>}

                                                </span>
                                            </span >

                                        </span>
                                    </p>
                                    <br />
                                </p>
                                <p className='mb-3'>
                                    <span >
                                        <span className='font-weight-normal  text-wrap' > Day-1: Haridwar to Pipalkoti(Drive)
                                        </span >

                                        <br /> <br />

                                        <div>
                                            <span className='blockquote-footer p-2'>
                                                <ul>
                                                    <li> Joshimath is 275 kilometers from Haridwar and has an altitude of 1900 meters (6,200
                                                        feet)</li>
                                                    <li>You will be picked up at 6:30 a.m. from Rishikesh.
                                                    </li>
                                                    <li>It will be a long journey of approximately 10-11 hours, so prepare properly.</li>
                                                    <li>Joshimath is where you will find the last ATM.
                                                    </li>
                                                    <li>There will also be a small market in Joshimath.
                                                    </li>
                                                    <li> You will not encounter any network issues all along the way</li>
                                                    <li>The night stay will be booked in a guesthouse with shared accommodation.</li>
                                                    <li>Driving Route</li>
                                                    <li>From Rishikesh to Devprayag, where the Bhagirathi and Alaknanda rivers converge.</li>
                                                    <li>Continuing from Devprayag to Srinagar.
                                                    </li>
                                                    <li> Proceeding from Srinagar to Rudrapryag, where the Mandakini and Alaknanda rivers
                                                        meet.
                                                    </li>
                                                    <li>Finally, travel from Rudraprayag to Karnprayag, where the Pindar and Alaknanda
                                                        rivers unite</li>
                                                </ul>
                                                Your experience with us begins in Rishikesh, where we will take a lovely drive along the
                                                famed Badrinath Road. The 9-hour trek to Pipalkoti may be long, but the beautiful views will
                                                keep you interested and intrigued throughout. As we ascend, the gorgeous river that flows
                                                alongside us becomes more tumultuous and mesmerizing.
                                                We'll pass through four of Ganga's five sacred confluences—Devaprayag, Rudraprayag,
                                                Karnaprayag, and Nandaprayag—with plenty of possibilities for photography and memorable
                                                encounters. So, keep your eyes open and your cameras ready to record the splendor of this
                                                amazing voyage.
                                            </span> <br />

                                            <div className=''>
                                                <img className='dis_img' src="../image/Day Valley 1.webp" alt="Image 1" />
                                            </div>

                                        </div>

                                    </span>


                                </p>
                                <p className='mb-3'>
                                    <span >
                                        <span className='font-weight-normal text-wrap' onClick={toggleContentVisibility2} >Day 2: Pipalkoti to Govindghat-Pulna, then hike to Ghangharia.
                                        </span >

                                        <br /> <br />
                                        {isContentVisible2 && (
                                            <p>
                                                <span >

                                                    <span className='blockquote-footer p-2'>
                                                        <ul>
                                                            <li>
                                                                It is a 40-kilometer trip from Govindghat to Pulna, which is located at an elevation of
                                                                2,000 meters (6,800 feet). The drive will last 4 hours.
                                                            </li>
                                                            <li>
                                                                Trekking for 9 kilometers from Pulna to Ghangharia, which is located at an elevation
                                                                of 2,290 meters/9,800 feet. You will arrive in Ghangharia in four hours.

                                                            </li>
                                                            <li>
                                                                You will hike up a gentle slope on a stone-paved trail alongside the river
                                                            </li>
                                                            <li>
                                                                Water is accessible all along the route, allowing you to conveniently replenish your
                                                                water bottles
                                                            </li>
                                                            <li>
                                                                Trekkers will be offered a packed lunch.

                                                            </li>
                                                            <li>
                                                                Mule and porter services are available starting from Pulna
                                                            </li>
                                                            <li>
                                                                BSNL network will only be available in Ghangaria.

                                                            </li>
                                                        </ul>
                                                        If the weather favorable, helicopter service is offered from Govind Ghat to Ghangaria.
                                                        Govindghat is nearly 22 kilometres from Joshimath. We'll leave after breakfast and arrive in
                                                        Govindghat about 8 a.m., followed by a 4-kilometer drive to Pulna, our starting site. Today's
                                                        travel is included in the price. The 9-kilometer uphill road runs parallel to the river
                                                        Pushpawati and is regularly crowded with tourists. Don't worry about getting hungry; there
                                                        are numerous snack stores along the trail providing beverages, hot noodles, omelets with
                                                        bread, paratha, and other goodies.
                                                        During the hike, you will follow a well-marked stone path with stairs. After walking 5
                                                        kilometers, you will arrive at the settlement of Bhyundar, which gives a breathtaking view of
                                                        Hathi Parvati. Take your time admiring the view, as the difficult uphill trek will begin shortly.
                                                        By dark, you will have reached Gangharia at an elevation of 10,000 feet. A guest home will
                                                        provide accommodation.

                                                    </span> <br />
                                                    <div className=''>
                                                        <img className='dis_img' src="../image/Day Valley 2.webp" alt="Image 1" />
                                                    </div>
                                                </span>

                                            </p>
                                        )}
                                    </span>


                                </p>
                                <p className='mb-3'>
                                    <span >
                                        <span className='font-weight-normal text-wrap w-90' onClick={toggleContentVisibility3} >Day 3: Ghangaria to Valley of Flowers, returning to Ghangaria Elevation:
                                            3,500m/11,500ft.
                                        </span >

                                        <br /> <br />
                                        {isContentVisible3 && (
                                            <p>
                                                <span >

                                                    <span className='blockquote-footer'>
                                                        <ul>
                                                            <li>Distance: 4 kilometres each way</li>
                                                            <li>Be prepared to trek for seven hours.</li>
                                                            <li>The valley is approximately 6-7 kilometers long and terminates with a glacier</li>
                                                            <li>Till the valley, you will discover mixed grade combined with a level walk across
                                                                grassland.</li>
                                                            <li> Along the walk, you'll come across various streams that are ideal for refilling your
                                                                water bottles.
                                                            </li>
                                                            <li>Packed lunch will be served because there is no food available in the valley</li>
                                                            <li>Camping is not permitted inside the valley, therefore we will return to Ghangaria
                                                                before sunset.
                                                            </li>
                                                            <li>A night's stay at a guest home will be planned</li>
                                                            <li>The valley is home to magnificent and rare flowers such as white leaf hog foot, blue
                                                                poppy, Himalayan rose, snake foil, dog flower, river anemone, meadow geranium,
                                                                and hooked stick seed.</li>
                                                            <li></li>
                                                            <li></li>
                                                            <li></li>
                                                        </ul>
                                                        Get up early and have breakfast until 7:00 a.m. Day 3 will be pretty thrilling as we will trek
                                                        from Ghangaria to the Valley of Flowers and then return. This day will undoubtedly fill your
                                                        recollections with some incredible and unforgettable views of dreamland. The walk to Valley
                                                        will allow you to feel nature's solitude without the rush and bustle of city life, as this valley is
                                                        both beautiful and a natural haven.
                                                        The hike from Ghangaria is roughly 4 kilometers long. The valley is 6-7 kilometers long, and
                                                        the distance traveled will be determined by how the trekkers choose to explore it. However, it
                                                        will also rely on the stamina of the hikers. The woodland checkpoint will arrive after 30
                                                    </span> <br />
                                                    <div className=''>
                                                        <img className='dis_img' src="../image/Day Valley 3.webp" alt="Image 1" />
                                                    </div>
                                                </span>

                                            </p>
                                        )}
                                    </span>


                                </p>
                                <p className='mb-3'>
                                    <span >
                                        <span className='font-weight-normal text-wrap w-90' onClick={toggleContentVisibility4} >Day 4: Ghangaria to Hemkund Sahib; return to Ghangaria.
                                        </span >

                                        <br /> <br />
                                        {isContentVisible4 && (
                                            <p>
                                                <span className='blockquote-footer'>
                                                    <ul>
                                                        <li>At an elevation of 4,300 meters/14,100 feet.
                                                        </li>
                                                        <li>The trip is 5.5 kilometers each way, making for a 7-hour trek</li>
                                                        <li>The hike route features a moderate and severe mixed rise</li>
                                                        <li>There are a mule ride service provided up to Hemkund Sahib.
                                                        </li>
                                                        <li>
                                                            Water bottles can readily be refilled at roadside dhabas</li>
                                                        <li>You will be served a hot lunch at the Gurudwara langar.
                                                        </li>
                                                        <li>On the road to Hemkund Sahib, you can see Himalayan blue poppy (Brahma Kamal).
                                                        </li>
                                                        <li>A night's stay will be planned at Ghangaria's guest house</li>
                                                    </ul>
                                                    After breakfast, we will begin our hike to the famed holy shrine of Hemkund Sahib. It is
                                                    positioned at an alpine elevation, above the tree line. The track quite wide and well-defined,
                                                    as pilgrims from all over the world visit Hemkund Sahib to seek blessings.
                                                    While trekking from Ghangaria to Hemkund Sahib, you will come across multiple tea vendors
                                                    and a Dhaba. Brahma Kamal blooms abundantly along this trail, when it approaches 4000m
                                                    in elevation. With a modest incline, the climb is continuous. It will take approximately 3 to 4
                                                    hours to reach Hemkund Sahib. You should be aware that the Hemkund Sahib is one of the
                                                    most important shrines for all Sikhs, as well as the world's highest gurudwara, standing at an
                                                    elevation of 4,329 meters.
                                                    The lake is a sight to behold, especially during the monsoon season when it remains
                                                    hazy.Don't forget to spend some time by the lake and enjoy a cup of tea provided solely by
                                                    the Gurudwara committee. This area is nestled between snow-capped mountains and a
                                                    beautiful calm lake that provides a stunning view of the magical setting.
                                                    Clear skies allow you to see the perfect impressions of the butting Saptrishi summits. This
                                                    lake is mostly maintained by dissolving ice masses from Saptrishi peaks and Hathi Parvat. In
                                                    addition, a strict Laxman sanctuary is located around the lake. It is critical for you to
                                                    understand that the environment surrounding the lake is so captivating that it will
                                                    undoubtedly blow your mind with its stunning beauty and fascinating vistas of gorgeous
                                                    flowers such as Brahmakamal, blue poppy, and Vajardanti.
                                                    Since the weather can change at any time, we will return to Ghangaria in the evening and
                                                    offer you hot and excellent food. A night's stay will also be planned here. Prepare to have an
                                                    incredible time and create lasting memories.

                                                </span> <br />
                                                <div className=''>
                                                    <img className='dis_img' src="../image/Day Valley 4.webp" alt="Image 1" />
                                                </div>


                                            </p>
                                        )}
                                    </span>


                                </p>
                                <p className='mb-3'>
                                    <span >
                                        <span className='font-weight-normal text-wrap w-90' onClick={toggleContentVisibility5} >Day 5: Ghangaria to Govindghat and drive back to Pipalkoti
                                        </span >

                                        <br /> <br />
                                        {isContentVisible5 && (
                                            <p>
                                                <span >

                                                    <span className='blockquote-footer'>
                                                        <ul>
                                                            <li>The trekking journey from Ghangaria to Govindghat is 13 kilometers and would take
                                                                5 hours.</li>
                                                            <li>Govindghat and Joshimath are only a 20-minute drive apart.</li>
                                                            <li>All trekkers will be provided a hot and delicious packed lunch along the way</li>
                                                            <li>A night's stay will be planned at Joshimath's guest house</li>

                                                        </ul>
                                                        After breakfast, you descend to Govindghat before driving back to Joshimath. Badrinath is
                                                        around 25 kilometres away and will take one hour to reach. If the weather and road
                                                        conditions are favorable, we will also visit Badrinath. On day 5, the trip will reverse direction,
                                                        going from Ghangaria to Govindghat. The trek is simple and provides a breathtaking glimpse
                                                        of nature that you might not have expected. We will only travel to Badrinath if the weather
                                                        and road conditions are favorable. The travel to Badrinath is quite fantastic because it has a
                                                        lovely view of waterfalls as well as a beautiful river.

                                                    </span> <br />
                                                    <div className=''>
                                                        <img className='dis_img' src="../image/day Valley 5.webp" alt="Image 1" />
                                                    </div>
                                                </span>

                                            </p>
                                        )}
                                    </span>


                                </p>

                                <p className='mb-3'>
                                    <span >
                                        <span className='font-weight-normal  text-wrap w-90' onClick={toggleContentVisibility6} >Day 6: Pipalkoti to Rishikesh.
                                        </span >

                                        <br /> <br />
                                        {isContentVisible6 && (
                                            <p>
                                                <span className='blockquote-footer'>
                                                    <ul>
                                                        <li>After tea or coffee, we will travel to Rishikesh from Pipalkoti.</li>
                                                        <li>You will arrive in Haridwar in the evening, about 6 to 7 p.m.</li>
                                                        <li>Make your bookings and tickets appropriately.
                                                        </li>
                                                        <li>You can also get tickets for your hometown after 8:30 PM.
                                                        </li>
                                                        <li>This is the final day of the journey, so make the most of it. We'll drive back to
                                                            Rishikesh at 6 a.m. and arrive about 6:30-7:30 p.m. Breakfast and lunch on the
                                                            journey are at your own expense.
                                                        </li>
                                                        <li>What food we provide on Valley of Flowers trek.
                                                        </li>
                                                    </ul>We only serve Indian vegetarian food, and your main course will consist of Indian bread,
                                                    vegetables, lentils, and rice, as well as a delectable dessert. Throughout your journey, we
                                                    will serve three meals per day: breakfast, lunch, and dinner. Before dinner, you will be
                                                    served tea and nibbles, as well as a delicious soup. If you are going on a long day of
                                                    trekking, you will be given a packed snack.
                                                    We create the menu after extensive research into the nutritional needs of the trekkers.
                                                    Before assembling all of the meals, we consider the height climbed and the weather.
                                                    Our journey meals are precisely balanced in terms of calories, carbohydrates, vitamins,
                                                    protein, fiber, and minerals. You should be aware that all of our cooks are highly skilled chefs
                                                    who have received extensive training. So, prepare to savor scrumptious and mouthwatering
                                                    dishes during your adventure. Lemon tea will be served in the tent to help you start the day
                                                    feeling refreshed. Before leaving the campground for trekking, you will be served a hot
                                                    finger-licking breakfast of upma, Aallu Prantha, Besan Chilla, Poha, Daliya, Corn flakes, and
                                                    Maggie, along with tea or coffee.
                                                </span> <br />
                                                <div className=''>
                                                    <img className='dis_img' src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSRZMWJFiSpiY-gM2D7YZ0LPLT2OpMKMv6WSQ&s" alt="Image 1" />
                                                </div>
                                            </p>
                                        )}
                                    </span>


                                </p>


                            </div>
                        </div>

                        <div className='col-lg-4 mb-2 col-md-4 col-sm-12 border form-body'>
                            <div className='p-2'>
                                <strong className='d-flex justify-content-end'>Enquiry Now !</strong>
                                <form >
                                    <div className="form-group">
                                        <label htmlFor="exampleInputEmail1">Name</label>
                                        <input
                                            type="text"
                                            className="form-control"

                                            aria-describedby="emailHelp"
                                            placeholder="Name"
                                            value={data.username} // Corrected variable name
                                            name='username' // Corrected variable name
                                            onChange={(e) => handleValue(e, 'username')} // Pass name as second argument
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="exampleInputPassword1">Email</label>
                                        <input
                                            type="email"
                                            className="form-control"

                                            placeholder="email"
                                            value={data.email}
                                            name='email'
                                            onChange={(e) => handleValue(e, 'email')}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="exampleInputPassword1">Number</label>
                                        <input
                                            type="number"
                                            className="form-control"

                                            placeholder="number"
                                            value={data.mobile}
                                            name='mobile'
                                            onChange={(e) => handleValue(e, 'mobile')}
                                        />
                                    </div>
                                    <button type="submit" className="btn btn-outline-secondary" onClick={handlesubmit}   disabled={isLoading}
                                        style={{ padding: ".5rem .5rem", fontSize: "1rem" ,backgroundColor: isLoading ? 'grey' : 'blue'}}>
                                        <span className='text-white'> {isLoading ? 'Loading...' : 'Submit'}</span>
                                    </button>
{/* 
                                    <button
                  type="submit"
                  onClick={handlesubmit}
                  className="btn btn-grey mx-auto"
                  style={{ backgroundColor: isLoading ? 'grey' : 'blue' }}
                  disabled={isLoading}
                >
                  {isLoading ? 'Loading...' : 'Submit'}
                </button> */}
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
                <div className='container mb-3'>
                    <div className='row mb-2'>
                        <div className='col-lg-12 col-md-12 col-sm-12 '>
                            <Carousel
                                swipeable={false}
                                draggable={false}
                                showDots={true}
                                responsive={responsive}
                                ssr={true} // means to render carousel on server-side.
                                infinite={true}
                                autoPlay={true}
                                autoPlaySpeed={2000}
                                keyBoardControl={true}
                                customTransition="all .5"
                                transitionDuration={500}
                                containerClass="carousel-container"
                                removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
                                deviceType="desktop"
                            // dotListClass="custom-dot-list-style"
                            // itemClass="carousel-item-padding-40-px"
                            >
                                <div className='d-flex justify-content-center '>
                                    <div className="card" style={{ width: "18rem" }}>
                                        <img src="../image/Valley of flower trek.webp" className="card-img-top" alt="customer-review" />
                                        <div className="card-body">
                                            <h5 className="card-title">Shivani Pooran     <span style={{ color: 'gold' }}>★★★★★</span></h5>
                                            <p className="card-text">
                                                We just finished the Valley of Flowers hike, which was our first time there ,Valley of Flowers Trek in 2021 will have a positive
                                                experience
                                            </p>

                                        </div>
                                    </div>

                                </div>
                                <div className='d-flex justify-content-center '>
                                    <div className="card" style={{ width: "18rem" }}>
                                        <img src="../image/Valley of flower.webp" className="card-img-top" alt="customer-review" />
                                        <div className="card-body">
                                            <h5 className="card-title">Shivani Pooran     <span style={{ color: 'gold' }}>★★★★★</span></h5>
                                            <p className="card-text">
                                                A captivating digital portal with breathtaking imagery, providing essential information for exploring this natural gem.
                                            </p>

                                        </div>
                                    </div>

                                </div>
                                <div className='d-flex justify-content-center '>
                                    <div className="card" style={{ width: "18rem" }}>
                                        <img src="../image/Valey of flowers uttarakhand.webp" className="card-img-top" alt="customer-review" />
                                        <div className="card-body">
                                            <h5 className="card-title">Mountainer Group Boyies<span style={{ color: 'gold' }}>★★★★★</span></h5>
                                            <p className="card-text">
                                                I performed the Valley of Flowers trek and Hemkund trek from August 5 to August 10, 2021.
                                                My stay at Trekkinindia in Joshimoth with my seven buddies was enjoyable.
                                            </p>

                                        </div>
                                    </div>

                                </div>
                                <div className='d-flex justify-content-center '>
                                    <div className="card" style={{ width: "18rem" }}>
                                        <img src="../image/Valey of flowers trek review.webp" className="card-img-top" alt="customer-review" />
                                        <div className="card-body">
                                            <h5 className="card-title">Akansha Jha<span style={{ color: 'gold' }}>★★★★★</span>
                                            </h5>
                                            <p className="card-text">
                                                An immersive online experience, capturing the essence of the Himalayan valley's beauty and inviting exploration.
                                            </p>

                                        </div>
                                    </div>

                                </div>
                            </Carousel>
                        </div>
                    </div>
                </div>
                <div className='container pt-md-5 pt-sm-2 mb-3' >
                    <strong className='bg-grey' >
                        FrequentLy Asked Q&A...
                    </strong>

                    <div className='pt-3'>
                        <p className='mb-3'>
                            <span >
                                <span className='font-weight-normal blockquote text-wrap w-90' > What is the Valley of Flowers Trek?
                                </span >

                                <br /> <br />

                                <p>
                                    <span className='blockquote-footer'>
                                        The Valley of Flowers Trek is a mesmerizing journey nestled in the Garhwal Himalayas
                                        of Uttarakhand, India. A UNESCO World Heritage Site renowned for its vibrant alpine
                                        flowers, breathtaking landscapes, and diverse flora and fauna.
                                    </span>
                                </p>

                            </span>


                        </p>
                        <p className='mb-3'>
                            <span >
                                <span className='font-weight-normal blockquote text-wrap w-90' >   Where is Valley of Flowers located?
                                </span >

                                <br /> <br />

                                <p>
                                    <span className='blockquote-footer'>
                                        The Valley of Flowers is located in the Chamoli district of Uttarakhand, India.
                                    </span>
                                </p>

                            </span>


                        </p>
                        <p className='mb-3'>
                            <span >
                                <span className='font-weight-normal blockquote text-wrap w-90'  >  What is the best time to visit the valley of Flowers?
                                </span >

                                <br /> <br />

                                <p>
                                    <span className='blockquote-footer'>
                                        The Valley of Flowers Trek best time is from July to September. During this period,
                                        the valley is in full bloom with a vibrant array of alpine flowers. The monsoon season,
                                        particularly in August, brings ample rainfall, which nourishes the flora and enhances the
                                        scenic beauty of the valley. Additionally, the weather during these months is generally
                                        favorable for trekking, with mild temperatures and clear skies, making it an ideal time to
                                        explore this enchanting destination.
                                    </span>
                                </p>

                            </span>


                        </p>
                        <p className='mb-3'>
                            <span >
                                <span className='font-weight-normal blockquote text-wrap w-90'  > How much time does it take to complete the Valley of Flower trek in Uttarakhand?
                                </span >

                                <br /> <br />

                                <p>
                                    <span className='blockquote-footer'>
                                        The valley of flowers uttarakhand trek typically takes around 2 to 3 days to complete.
                                        The valley of flowers trek distance is approximately 10 kilometres one way. Depending on
                                        your pace and the duration of breaks taken along the trail, it usually takes about 4 to 6 hours
                                        to reach the valley from Govindghat. After spending time exploring the valley, trekkers
                                        usually return to Govindghat on the same day or the following day, making it a total of 2 to 3
                                        days for the trek to valley of flowers uttarakhand
                                    </span>
                                </p>

                            </span>


                        </p>
                        <p className='mb-3'>
                            <span >
                                <span className='font-weight-normal blockquote text-wrap w-90'  >What is the valley of flowers trek package?
                                </span >

                                <br /> <br />

                                <p>
                                    <span className='blockquote-footer'>
                                        The Valley of Flowers Trek cost is 10000.
                                    </span>
                                </p>

                            </span>


                        </p>
                    </div>
                </div>

                <>

                    {/* Modal */}
                    <div
                        className="modal fade"
                        id="exampleModal"
                        tabIndex={-1}
                        role="dialog"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                    >
                        <div className="modal-dialog " role="document">
                            <div className="modal-content " style={{ backgroundColor: "currentcolor" }}>
                                <div className="modal-header">
                                    <h5 className="modal-title text-white" id="exampleModalLabel">
                                        Enquiry Now !
                                    </h5>
                                    <button
                                        type="button"
                                        className="close text-white"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                    >
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <div className="modal-body border">
                                    <form >
                                        <div className="form-group">

                                            <input
                                                type="text"
                                                className="form-control"

                                                aria-describedby="emailHelp"
                                                placeholder="Name"
                                                value={data.username} // Corrected variable name
                                                name='username' // Corrected variable name
                                                onChange={(e) => handleValue(e, 'username')} // Pass name as second argument
                                            />
                                        </div>
                                        <div className="form-group">

                                            <input
                                                type="email"
                                                className="form-control"

                                                placeholder="email"
                                                value={data.email}
                                                name='email'
                                                onChange={(e) => handleValue(e, 'email')}
                                            />
                                        </div>
                                        <div className="form-group">

                                            <input
                                                type="number"
                                                className="form-control"

                                                placeholder="number"
                                                value={data.mobile}
                                                name='mobile'
                                                onChange={(e) => handleValue(e, 'mobile')}
                                            />
                                        </div>
                                        {/* <button type="submit" className="btn  btn-grey mx-auto" onClick={handlesubmit}>
                                            Submit
                                        </button> */}
                                        <button
                  type="submit"
                  onClick={handlesubmit}
                  className="btn btn-grey mx-auto"
                  style={{ backgroundColor: isLoading ? 'grey' : 'blue' }}
                  disabled={isLoading}
                >
                  {isLoading ? 'Loading...' : 'Submit'}
                </button>
                                       
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            </div>
        </>
    )
}

export default Home