import React from 'react'

const Header = () => {
    return (
        <div className='container-fluid bg'>
            <div className=''>
            <div className='row '>
                <div className='col-4  d-flex  mt-3'>
                    <div className=' py-2 '>
                    <img className="logo" src="../image/Trekweblogo.png" alt="First slide"  />
                    </div>
                </div>
                <div className='col-4'>

                </div>
                <div className='col-4 '>
                    <div className='top-fixed   '>
                        <p className=' py-3 d-flex justify-content-end mt-2 '>
                            <a href='tel:7987093818'> <span className=' text-white px-lg-5 px-md-5 number '>7987093818</span></a>
                        </p>

                    </div>
                </div>
            </div>
            </div>
        </div>

    )
}

export default Header